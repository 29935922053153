<template>
  <div class="contaner_ma">
    <new-header :config="headerConfig"></new-header>
    <div class="content_details">
      <div class="status_text">
        <div>
          申请时间：<span style="color:rgba(26,26,26,0.7);">{{ tableData.create_time }}</span>
        </div>
        <div style="color: #0091ff" @click="errorBtn" v-if="tableData.close_state == 0 && tableData.audit_state < 6">
          <span style="color: #FF8A00">进行中...</span>
        </div>
        <div style="color: #0091ff" @click="errorBtn" v-if="tableData.close_state == 1">
          <span style="color: #ff3232">已拒绝/</span>查看理由 >
        </div>
        <div style="color: #0091ff" v-if="tableData.close_state == 0 && tableData.audit_state == 6">
          <span style="color: #3DAB39">已完成</span>
        </div>
      </div>
      <div class="status_text" v-if="status == 1">
        <div>物料数量：<span style="color: rgba(26,26,26,0.7);">{{ tableData.audit_info.length }}</span></div>
        <div>提交数量：<span style="color: rgba(26,26,26,0.7);">{{ tableData.audit_shares }}</span></div>
        <div>累计工分：<span style="color: rgba(26,26,26,0.7);">{{ tableData.audit_shares_amount }}</span></div>
      </div>

      <div class="status_text" v-if="status == 2">
        <div>物料数量：<span style="color: rgba(26,26,26,0.7);">{{ tableData.audit_contract.length }}</span></div>
        <div>提交数量：<span style="color: rgba(26,26,26,0.7);">{{ tableData.audit_contract_amount }}</span></div>
        <div>累计工分：<span style="color: rgba(26,26,26,0.7);">{{ tableData.audit_shares_amount }}</span></div>
      </div>

      <div v-if="status == 1">
        <div class="content_list_ma" v-for="(item, index) in tableData.audit_info" :key="index">
          <img class="content_img" @click="lookIcon(item.img)" :src="item.img" />
          <div class="user_box">
            <div>姓名：<span>{{ item.name }}</span></div>
            <div>工分：<span>{{ item.shares }}</span></div>
          </div>
        </div>
      </div>
      <!-- 电子合同 -->
      <div v-if="status == 2">
        <div class="content_electronic" v-for="(item, index) in tableData.audit_contract" :key="index">
          <div>编号:{{ item.contract_no }}</div>
          <div class="content_book">
            <div>合同名称：{{ item.contract_name }}</div>
            <div>工分：{{ item.contract_shares }}</div>
          </div>
        </div>

        <div class="user_info" >
          <div>用户账户：<span>{{ tableData.buyer.union_info?tableData.buyer.union_info.phone:'暂无' }}</span></div>
          <div>购购账户：<span>{{ tableData.buyer.member.member_mobile }}</span></div>
          <div>会员昵称：<span>{{ tableData.buyer.union_info?tableData.buyer.union_info.username:'暂无' }}</span></div>
          <div>证件号码：<span>{{ tableData.buyer.union_info?tableData.buyer.union_info.identity:'暂无' }}</span></div>
        </div>
      </div>

      <div class="price" v-if="tableData.pay_info">
        服务费：<span style="color: rgba(26, 26, 26, 0.7)">{{ tableData.pay_info[0].order_amount }}</span>
      </div>

      <!-- 选择订单抵扣 -->
      <div class="order_discoun" v-if="tableData.pay_info">
        <div class="discoun" v-for="(item, index) in tableData.pay_info" :key="index">
          <img class="discoun_img" :src="item.goods_image" />
          <div class="text_info">
            <div class="title">{{ item.goods_name }}</div>
            <div>订单编号：{{ item.order_sn }}</div>
            <div>订单时间：{{ item.add_time }}</div>
            <div>订单金额：{{ item.order_amount }}</div>
          </div>
        </div>
        <div class="status_text1">已完成</div>
      </div>

      <!-- 实物物料 -->
      <div v-if="status == 1">
        <div class="message_title">物料信息</div>
        <div class="logistics_info_l" style="margin-bottom:20px" v-if="detailsList.shipments">
          <div>{{ detailsList.shipments[0].shipments_info.LogisticCode }}</div>
          <div class="address_a">{{ detailsList.shipments[0].shipments_info.Shipper }}</div>
          <div v-if="showBtn" class="right_l_s" style="color:rgba(29, 140, 254, 1)" @click="falyBtn">展开 ></div>
          <div v-else class="right_l_s" style="color:rgba(29, 140, 254, 1)" @click="falyBtn">收起 ></div>
        </div>
        <!-- 暂无物流信息 -->
        <div class="logistics_meesage_l" v-if="tableData.shipments.length == 0">
          <img src="../../assets/cart.png" />
          <div>暂无数据</div>
        </div>

        <div v-else>
          <logistics :dataList="dataList" :showBtn="showBtn" :alterationShow="alterationShow" :tableData="tableData">
          </logistics>
        </div>
      </div>
    </div>

    <!-- 提示弹窗 -->
    <van-dialog v-model="show" :show-confirm-button="false" width="85%">
      <div class="cancel_box">
        <img src="https://cdn.health.healthplatform.xyz/FsweN_GNOrZLicejPFZmx0rHnVBq" />
        <div class="text_box">
          <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
          <div class="message_title">温馨提示</div>
          <div class="message_text">因xxxxx原因，您的变更申请未通过，请重新尝试。</div>
          <div class="sure_btn">确认</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { sharesChangeDetailsNew_api,sharesChangeDetailsNew_s_api } from "@/api/alteration";
import logistics from "@/components/logistics";
import { getShipments_api } from "@/api/admin";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      headerConfig:{
        show: true,
        title: '实物物料详情',
      },
      show: false,
      status: 1,
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      detailsList: {
        shipments: [
          { shipments_info: {} }
        ]
      },
      dataList: {},
      tableData: {},
      alterationShow: true,
      showBtn: true
    };
  },
  components: {
    newHeader,
    logistics
  },
  created() {
    document.title = "";
    this.status = this.$route.query.status;
    if (this.status == 1) {
      this.getDetails(this.$route.query.id);
      this.headerConfig.title = "实物物料详情"

    } else {
      this.headerConfig.title = "电子物料详情"
      this.getDetails_s(this.$route.query.id);
    }


    console.log(this.status);
  },
  methods: {
    errorBtn() {
      this.show = true;
    },
    cloneBtn() {
      this.show = false;
    },
    // 查看合同
    lookIcon(img) {
      console.log(img);
      ImagePreview([img]);
    },
    falyBtn() {
      this.showBtn = !this.showBtn
    },
    getDetails(id) {
      sharesChangeDetailsNew_api(id).then(res => {
        if (res.code == 0) {
          this.detailsList = res.data;
          this.tableData = res.data;
          this.tableData.audit_shares = 0
          res.data.audit_info.forEach(e => {
            this.tableData.audit_shares = this.tableData.audit_shares + Number(e.shares)
          })
          this.getShipments(this.detailsList.shipments[0].shipments_info)
        }
      })
    },
    getDetails_s(id) {
      sharesChangeDetailsNew_s_api(id).then(res => {
        if (res.code == 0) {
          this.detailsList = res.data;
          this.tableData = res.data;
          this.tableData.audit_shares = 0
          res.data.audit_info.forEach(e => {
            this.tableData.audit_shares = this.tableData.audit_shares + Number(e.shares)
          })
          this.getShipments(this.detailsList.shipments[0].shipments_info)
        }
      })
    },
    submitBtn() { },
    getShipments(data) {
      data.logistic = 0
      getShipments_api(data).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.dataList = res.data
          this.dataList.Traces.reverse();
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.contaner_ma {
  background: #f9f9fb;
  width: 100%;

  .cancel_box {
    width: 100%;
    height: 450px;
    background: linear-gradient(180deg, #a6c9ff 0%, #ffffff 50%);
    border-radius: 30px 30px 30px 30px;
    position: relative;
    text-align: center;
    padding: 51px 57px 20px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }

    .text_box {
      img {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 0px;
      }

      >div {
        margin-bottom: 26px;
      }

      .message_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 40px;
        color: #1a1a1a;
        margin-bottom: 50px;
      }

      .message_text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.8);
        text-align: center;
        padding: 0 57px;
        box-sizing: border-box;
      }

      .message_text1 {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 32px;
        color: #0091ff;
      }

      .sure_btn {
        width: 180px;
        height: 66px;
        background: #0091ff;
        border-radius: 50px 50px 50px 50px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #ffffff;
        text-align: center;
        line-height: 66px;
        // margin-top: 70px;
        margin: 70px auto 0;
      }
    }

    >div {
      position: absolute;
      left: 0;
      top: 51px;
    }
  }

  .order_discoun {
    width: 700px;
    height: 160px;
    background: #fff;
    border-radius: 10px 10px 10px 10px;
    padding: 20px 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    .status_text1 {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #0091ff;
      margin-top: 30px;
    }

    .discoun {
      display: flex;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: rgba(26, 26, 26, 0.8);

      .text_info {
        margin-left: 13px;

        div {
          line-height: 30px;
        }

        .title {
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 22px;
          color: #1a1a1a;
        }
      }

      .discoun_img {
        width: 121px;
        height: 121px;
        border-radius: 6px 6px 6px 6px;
        // background-color: skyblue;
      }
    }
  }

  .content_details {
    padding: 40px 30px;
    box-sizing: border-box;

    .van-steps {
      background: #f9f9fb;
      font-size: 28px;
    }

    .content_electronic {
      width: 700px;
      height: 120px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 25px;
      box-sizing: border-box;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 19px;

      .content_book {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }

    .user_info {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1a1a1a;
      margin-top: 50px;

      div {
        margin-bottom: 20px;
      }
    }

    .message_title {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 30px;
      color: #1a1a1a;
      margin-bottom: 30px;
    }

    .order_sn {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: rgba(26, 26, 26, 0.7);

      span {
        margin-left: 20px;
      }
    }

    .price {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1a1a1a;
      margin: 40px 20px 0 0;
    }

    .status_text {
      display: flex;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #1a1a1a;
      margin-bottom: 40px;
    }

    .content_list_ma {
      width: 700px;
      height: 160px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 20px;
      display: flex;

      .content_img {
        width: 140px;
        height: 140px;
        border-radius: 6px 6px 6px 6px;
        // background: skyblue;
      }

      .user_box {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #1a1a1a;
        margin: 23px 0 0 34px;

        span {
          color: rgba(26, 26, 26, 0.7);
        }

        >div {
          margin-bottom: 20px;
        }
      }
    }
  }
}


.title_l_s {
  font-size: 34px;
  color: #1a1a1a;
  padding-bottom: 30px;
  margin-top: 30px
}

.content_box_l {
  display: flex;
}

.right_box_l {
  width: 420px;
  height: 80px;
  line-height: 40px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(26, 26, 26, 0.2);
  display: flex;
  font-size: 30px;
  padding: 20px 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.left_l {
  width: 180px;
  height: 180px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(26, 26, 26, 0.2);
  margin-left: 20px
}

.left_l img {
  width: 100%;
  height: 100%
}

.content_box_l {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee
}

.logistics_info_l {
  display: flex;
  font-size: 30px;
  color: rgba(26, 26, 26, 1);
  position: relative;
}

.right_l_s {
  position: absolute;
  right: 10px;
  top: 5px
}

.logistics_info_l div {
  margin-right: 20px
}

.logistics_meesage_l img {
  width: 70px;

}

.logistics_meesage_l {
  font-size: 24px;
  color: rgba(26, 26, 26, 0.30);
  text-align: center;
  margin-top: 50px
}
</style>
